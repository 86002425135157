<template>
    <div class="benefit">
        <CompTable title="上门巡访服务记录" :error="error" :columns="columns" method="POST" :table-api="tableApi" :dataBefore="onDataBefore" :processData="onProcessData" data="[]">
            <template v-slot:search="data">
                <Input v-model.trim="data.search.keyword" placeholder="长者姓名" style="width: 200px"></Input>
            </template>
        </CompTable>

        <CompModal ref="comp_modal" class="benefit-detail" :class="{ isexport: isExport }" title="服务记录详情" @on-load="onLoadModal">
            <div class="detail-base" v-if="detail" ref="detail_base">
                <div class="detail-box">
                    <div class="info-box">
                        <div class="item">姓名：{{ detail.userName }}</div>
                        <div class="item">性别：{{ ["未知", "男", "女"][detail.sex] || "-" }}</div>
                        <div class="item">年龄：{{ detail.age || "-" }}</div>
                    </div>

                    <div class="item-box">
                        <div class="title bold">
                            生命体征
                        </div>
                        <div class="content-box">
                            <div class="value-item">
                                <div class="name">体温</div>
                                <div class="value">{{ detail.tiWen || "-" }}</div>
                                <div class="unit">℃</div>
                            </div>
                            <div class="value-item">
                                <div class="name">脉搏</div>
                                <div class="value">{{ detail.maiBo || "-" }}</div>
                                <div class="unit">次/分钟</div>
                            </div>
                            <div class="value-item">
                                <div class="name">呼吸</div>
                                <div class="value">{{ detail.huXi || "-" }}</div>
                                <div class="unit">次/分钟</div>
                            </div>
                            <div class="value-item">
                                <div class="name">血压</div>
                                <div class="value">{{ detail.xueYa || "-" }}</div>
                                <div class="unit">mmHg</div>
                            </div>
                            <div class="value-item">
                                <div class="name">血糖</div>
                                <div class="value">4.5</div>
                                <div class="unit">mmol/L</div>
                            </div>
                        </div>
                    </div>

                    <div class="item-box">
                        <div class="title bold">
                            健康和疾病管理服务
                        </div>
                        <div class="content-box">
                            <div class="value-item">
                                <div class="name">中医理疗</div>
                                <div class="checkbox-box" v-if="detail.zyll && detail.zyll.length > 0">
                                    <checkbox :value="true" v-for="(item, idx) in typeof detail.zyll === 'string' ? [detail.zyll] : detail.zyll" :key="idx">{{ item }}</checkbox>
                                </div>
                                <span v-else>-</span>
                            </div>
                            <div class="value-item">
                                <div class="name">预防保健</div>
                                <div class="checkbox-box" v-if="detail.yfbj && detail.yfbj.length > 0">
                                    <checkbox :value="true" v-for="(item, idx) in typeof detail.yfbj === 'string' ? [detail.yfbj] : detail.yfbj" :key="idx">{{ item }}</checkbox>
                                </div>
                                <span v-else>-</span>
                            </div>
                            <div class="value-item">
                                <div class="name">医疗护理</div>
                                <div class="checkbox-box" v-if="detail.ylhl && detail.ylhl.length > 0">
                                    <checkbox :value="true" v-for="(item, idx) in typeof detail.ylhl === 'string' ? [detail.ylhl] : detail.ylhl" :key="idx">{{ item }}</checkbox>
                                </div>
                                <span v-else>-</span>
                            </div>
                        </div>
                    </div>

                    <div class="item-box">
                        <div class="title bold">
                            家庭照护技能培训
                        </div>
                        <div class="content-box">
                            <div class="value-item">
                                <div class="name">培训内容</div>
                                <div class="checkbox-box" v-if="detail.peiXunNeiRong && detail.peiXunNeiRong.length > 0">
                                    <checkbox :value="true" v-for="(item, idx) in typeof detail.peiXunNeiRong === 'string' ? [detail.peiXunNeiRong] : detail.peiXunNeiRong" :key="idx">{{ item }}</checkbox>
                                </div>
                                <span v-else>-</span>
                            </div>
                            <div class="value-item">
                                <div class="name">培训方式</div>
                                <div class="radio-box" v-if="detail.pxfs">
                                    <radio :value="true">{{ detail.pxfs }}</radio>
                                </div>
                                <span v-else>-</span>
                            </div>
                            <div class="value-item">
                                <div class="name">培训效果</div>
                                <div class="radio-box" v-if="detail.pxxg">
                                    <radio :value="true">{{ detail.pxxg }}</radio>
                                </div>
                                <span v-else>-</span>
                            </div>
                        </div>
                    </div>

                    <div class="item-box">
                        <div class="title bold">
                            特殊情况记录
                        </div>
                        <div class="content">{{ detail.tsqkjl || "无" }}</div>
                    </div>

                    <div class="item-box row">
                        <div class="title">
                            服务照片
                        </div>
                        <div class="content">
                            <img v-for="item in typeof detail.photo === 'string' ? [detail.photo] : detail.photo" :key="item" class="img" :src="item" @click="onPredivImage(detail.photo === 'string' ? [detail.photo] : detail.photo, item)" />
                        </div>
                    </div>

                    <div class="item-box row">
                        <div class="title">
                            上门时间
                        </div>
                        <div class="content">{{ detail.operatorTime || detail.homeServiceTime ? $core.formatDate(new Date(detail.operatorTime || detail.homeServiceTime), "yyyy年MM月dd日") : "-" }}</div>
                    </div>

                    <div class="item-box row">
                        <div class="title">
                            服务人员
                        </div>
                        <div class="content">{{ detail.nursingUser || detail.fwry || "-" }}</div>
                    </div>
                </div>

                <div class="appraise-box" v-if="appraise_value">
                    <div class="title">评价记录</div>
                    <CompForm :forms="appraise_forms" :value="appraise_value" :isDisplaySubmit="false" disabled></CompForm>
                </div>
            </div>
        </CompModal>

        <CompTabular ref="comp_tabular" title="巡访服务记录表" :columns="tabular_columns"></CompTabular>
    </div>
</template>

<script>
import Route from "../communitymobilization/entity/Route"
import CompForm from "../customform/components/CompForm"
import CompTable from "../jointly/components/comp-table.vue"
import Request from "../jointly/utils/request"
import Utils from "../jointly/utils/utils"
import CompModal from "../residentdatabase/components/CompModal"
import ElemLook from "../residentdatabase/components/ElemLook"
import CompTabular from "../customform/components/CompTabular.vue"
import ElemCheck from "../customform/components/ElemCheck"
import FileUtil from "../customform/utils/file"
import Loading from "../jointly/utils/loading"
import ElemButton from "../customform/components/ElemButton.vue"

export default {
    components: { CompTable, CompModal, CompForm, CompTabular },

    data() {
        const orgCode = this.$core.getUrlParam("orgCode") || parent.vue.loginInfo.userinfo.orgCode

        return {
            orgCode: orgCode,
            // 详情
            detail: null,
            // 路由控制器
            route: Route,
            // 表单接口
            tableApi: null,
            // 错误信息
            error: null,

            columns: [
                {
                    key: "姓名",
                },
                {
                    key: "性别",
                    width: 100,
                },
                {
                    key: "年龄",
                    width: 100,
                },
                {
                    title: "联系电话",
                    key: "手机号码",
                },
                {
                    title: "服务人员",
                    key: "护士签名",
                },
                {
                    title: "服务日期",
                    key: "日期",
                },
                {
                    title: "提交时间",
                    key: "创建时间",
                    width: 180,
                },
                {
                    title: "评价状态",
                    width: 150,
                    render: (h, params) => {
                        let done = params.row.status === "1"
                        return h(
                            "p",
                            {
                                style: {
                                    color: done ? "" : "red",
                                },
                            },
                            done ? "已评价" : "待评价"
                        )
                    },
                },
                {
                    float: "right",
                    title: "操作",
                    width: 350,
                    align: "center",
                    render: (h, params) => {
                        return h("div", [
                            h(ElemLook, {
                                style: "margin:0 3px",
                                props: {
                                    row: params.row,
                                    id: params.row.id,
                                    source: "data",
                                    appId: this.appId,
                                },
                                on: {
                                    "on-change": res => {
                                        this.$set(params.row, "姓名", res.value.userName || res.value["姓名"])
                                        this.$set(params.row, "手机号码", res.value.mobile?.replace(/["\[\]\\]/g, "") || res.value["手机号码"])
                                    },
                                },
                            }),
                            h(
                                "Button",
                                {
                                    props: {
                                        type: "info",
                                        size: "small",
                                    },
                                    style: "margin:0 3px",
                                    nativeOn: {
                                        click: () => {
                                            this.getDetail(params.row.id).then(() => {
                                                this.$refs.comp_modal.display()
                                            })
                                        },
                                    },
                                },
                                "记录"
                            ),
                            h(
                                "Button",
                                {
                                    props: {
                                        type: "success",
                                        size: "small",
                                    },
                                    style: "margin:0 3px",
                                    nativeOn: {
                                        click: () => {
                                            Request.get("/gateway/api/fastDev/AppData/findDataById/" + params.row.id).then(async res => {
                                                const d = res.dataMap

                                                Object.keys(d).forEach(k => {
                                                    if (typeof d[k] === "string" && /^\[.*\]$/.test(d[k])) {
                                                        d[k] = JSON.parse(d[k])
                                                    }
                                                })

                                                this.$refs.comp_tabular.display(d)
                                            })
                                        },
                                    },
                                },
                                "导出"
                            ),
                            h(
                                ElemButton,
                                {
                                    props: {
                                        type: "info",
                                        size: "small",
                                        onClick: async () => {
                                            return new Promise(resolve => {
                                                this.getDetail(params.row.id).then(() => {
                                                    Loading.show()

                                                    this.__onLoadModal = () => {
                                                        setTimeout(async () => {
                                                            await FileUtil.exportToPDF(this.$refs.detail_base)

                                                            this.$refs.comp_modal.close()
                                                            this.isExport = false

                                                            Loading.hide()

                                                            setTimeout(resolve, 1000)
                                                        }, 100)
                                                    }

                                                    this.isExport = true

                                                    this.$nextTick(() => {
                                                        this.$refs.comp_modal.display()
                                                    })
                                                })
                                            })
                                        },
                                    },
                                    style: "margin:0 3px",
                                },
                                "导出记录"
                            ),
                        ])
                    },
                },
            ],

            appraise_forms: null,
            appraise_value: null,

            tabular_columns: [
                {
                    title: "基本信息",
                    child: [
                        {
                            value: (h, d) => {
                                return h(
                                    "div",
                                    {
                                        style: {
                                            display: "flex",
                                            "align-items": "center",
                                        },
                                    },
                                    [
                                        ["姓名: " + d.userName, `年龄: ${d.age || ""}`, `性别: ${d.sex ? ["男", "女"][d.sex - 1] || "未知" : ""}`].map(v =>
                                            h(
                                                "p",
                                                {
                                                    style: {
                                                        flex: "1",
                                                    },
                                                },
                                                v
                                            )
                                        ),
                                    ]
                                )
                            },
                        },
                        {
                            child: [
                                {
                                    value: (h, d) => `联系电话：${d.mobile?.join?.(",") || ""}`,
                                },
                                {
                                    value: (h, d) => `住址：${d.address || ""}`,
                                },
                            ],
                        },
                    ],
                },
                {
                    title: "生命体征",
                    value: (h, d) => {
                        return h(
                            "div",
                            {
                                style: {
                                    display: "flex",
                                    "align-items": "center",
                                    "flex-wrap": "wrap",
                                },
                            },
                            [`体温: {${d.tiWen || ""}}℃`, `脉搏: {${d.maiBo || ""}}次/分`, `呼吸: {${d.huXi || ""}}次/分`, `血压：{${d.xueYa || ""}}mmHg`, `血糖：{${d.xueTang || ""}}`].map(v => {
                                let keys = v.match(/^(.*)\{(.*)\}(.*)$/)

                                return h(
                                    "div",
                                    {
                                        style: {
                                            margin: "5px 20px 5px 0",
                                            display: "flex",
                                            "flex-shrink": "0",
                                            "min-width": "50px",
                                        },
                                    },
                                    [
                                        h("p", keys[1]),
                                        h(
                                            "p",
                                            {
                                                style: {
                                                    "border-bottom": "1px solid #e3e3e3",
                                                    margin: "0 5px",
                                                    padding: "0 10px",
                                                },
                                            },
                                            keys[2]
                                        ),
                                        ...(keys.length >= 3 ? [h("p", keys[3])] : []),
                                    ]
                                )
                            })
                        )
                    },
                },
                {
                    title: "健康和疾病管理服务",
                    value: (h, d) => {
                        return h("div", [
                            h(
                                "div",
                                {
                                    style: {
                                        display: "flex",
                                        margin: "5px 0",
                                    },
                                },
                                [
                                    h(
                                        "p",
                                        {
                                            style: {
                                                "line-height": "25px",
                                                "flex-shrink": "0",
                                            },
                                        },
                                        "中医理疗："
                                    ),
                                    h(ElemCheck, {
                                        props: {
                                            value: d.zyll,
                                        },
                                        attrs: {
                                            multiple: true,
                                            list: [
                                                {
                                                    value: "穴位贴敷",
                                                },
                                                {
                                                    value: "艾灸",
                                                },
                                                {
                                                    value: "耳穴贴压",
                                                },
                                                {
                                                    value: "穴位按摩",
                                                },
                                                {
                                                    value: "其他中医理疗",
                                                    extend: "input",
                                                },
                                            ],
                                        },
                                    }),
                                ]
                            ),
                            h(
                                "div",
                                {
                                    style: {
                                        display: "flex",
                                        margin: "5px 0",
                                    },
                                },
                                [
                                    h(
                                        "p",
                                        {
                                            style: {
                                                "line-height": "25px",
                                                "flex-shrink": "0",
                                            },
                                        },
                                        "预防保健："
                                    ),
                                    h(ElemCheck, {
                                        props: {
                                            value: d.yfbj,
                                        },
                                        attrs: {
                                            multiple: true,
                                            list: [
                                                {
                                                    value: "健康咨询等",
                                                },
                                                {
                                                    value: "用药指导等",
                                                },
                                            ],
                                        },
                                    }),
                                ]
                            ),
                            h(
                                "div",
                                {
                                    style: {
                                        display: "flex",
                                        margin: "5px 0",
                                    },
                                },
                                [
                                    h(
                                        "p",
                                        {
                                            style: {
                                                "line-height": "25px",
                                                "flex-shrink": "0",
                                            },
                                        },
                                        "医疗护理："
                                    ),
                                    h(ElemCheck, {
                                        props: {
                                            value: d.ylhl,
                                        },
                                        attrs: {
                                            multiple: true,
                                            list: [
                                                {
                                                    value: "基础监测",
                                                },
                                                {
                                                    value: "其他专业护理项目",
                                                    extend: "input",
                                                },
                                            ],
                                        },
                                    }),
                                ]
                            ),
                            h(
                                "div",
                                {
                                    style: {
                                        display: "flex",
                                        margin: "5px 0",
                                    },
                                },
                                [
                                    h(
                                        "p",
                                        {
                                            style: {
                                                "line-height": "25px",
                                                "flex-shrink": "0",
                                            },
                                        },
                                        "情感关怀："
                                    ),
                                    h(ElemCheck, {
                                        props: {
                                            value: d.qggh,
                                        },
                                        attrs: {
                                            multiple: true,
                                            list: [
                                                {
                                                    value: "情感关怀",
                                                },
                                            ],
                                        },
                                    }),
                                ]
                            ),
                        ])
                    },
                },
                {
                    title: "家庭照护技能培训",
                    child: [
                        {
                            value: (h, d) => {
                                return h(
                                    "div",
                                    {
                                        style: {
                                            display: "flex",
                                            "align-items": "center",
                                        },
                                    },
                                    [
                                        h(
                                            "p",
                                            {
                                                style: {
                                                    "flex-shrink": "0",
                                                },
                                            },
                                            "培训内容："
                                        ),
                                        h(ElemCheck, {
                                            props: {
                                                value: d.peiXunNeiRong,
                                            },
                                            attrs: {
                                                multiple: true,
                                                list: [
                                                    {
                                                        value: "房间设施安全",
                                                    },
                                                    {
                                                        value: "室外活动安全",
                                                    },
                                                    {
                                                        value: "拐杖、助行器使用",
                                                    },
                                                    {
                                                        value: "协助移动",
                                                    },
                                                    {
                                                        value: "穿、更衣",
                                                    },
                                                    {
                                                        value: "面部清洁",
                                                    },
                                                    {
                                                        value: "口腔护理",
                                                    },
                                                    {
                                                        value: "温水擦浴/沐浴协助",
                                                    },
                                                    {
                                                        value: "床上洗头",
                                                    },
                                                    {
                                                        value: "睡眠护理",
                                                    },
                                                    {
                                                        value: "鼻伺",
                                                    },
                                                    {
                                                        value: "喂饭",
                                                    },
                                                    {
                                                        value: "协助安全用药",
                                                    },
                                                    {
                                                        value: "协助更换体位、拍背",
                                                    },
                                                    {
                                                        value: "协助肢体功能活动",
                                                    },
                                                    {
                                                        value: "失禁护理",
                                                    },
                                                    {
                                                        value: "床上使用便器",
                                                    },
                                                    {
                                                        value: "留置尿管护理",
                                                    },
                                                    {
                                                        value: "尿排泄障碍护理",
                                                    },
                                                    {
                                                        value: "肠胀气、便秘护理",
                                                    },
                                                    {
                                                        value: "造瘘口护理",
                                                    },
                                                    {
                                                        value: "关注心理需求",
                                                    },
                                                    {
                                                        value: "其他",
                                                        extend: "input",
                                                    },
                                                ],
                                            },
                                        }),
                                    ]
                                )
                            },
                        },
                        {
                            value: (h, d) => {
                                return h("div", [
                                    h(
                                        "div",
                                        {
                                            style: {
                                                display: "flex",
                                                margin: "5px 0",
                                            },
                                        },
                                        [
                                            h(
                                                "p",
                                                {
                                                    style: {
                                                        "line-height": "25px",
                                                        "flex-shrink": "0",
                                                    },
                                                },
                                                "培训方式："
                                            ),
                                            h(ElemCheck, {
                                                props: {
                                                    value: d.pxfs,
                                                },
                                                attrs: {
                                                    multiple: true,
                                                    list: [
                                                        {
                                                            value: "入户一对一指导",
                                                        },
                                                        {
                                                            value: "线上",
                                                        },
                                                    ],
                                                },
                                            }),
                                        ]
                                    ),
                                    h(
                                        "div",
                                        {
                                            style: {
                                                display: "flex",
                                                margin: "5px 0",
                                            },
                                        },
                                        [
                                            h(
                                                "p",
                                                {
                                                    style: {
                                                        "line-height": "25px",
                                                        "flex-shrink": "0",
                                                    },
                                                },
                                                "培训效果："
                                            ),
                                            h(ElemCheck, {
                                                props: {
                                                    value: d.pxxg,
                                                },
                                                attrs: {
                                                    multiple: true,
                                                    list: [
                                                        {
                                                            value: "熟练掌握",
                                                        },
                                                        {
                                                            value: "掌握",
                                                        },
                                                        {
                                                            value: "熟悉",
                                                        },
                                                        {
                                                            value: "了解",
                                                        },
                                                        {
                                                            value: "未掌握",
                                                        },
                                                    ],
                                                },
                                            }),
                                        ]
                                    ),
                                ])
                            },
                        },
                    ],
                },
                {
                    title: "特殊情况记录",
                    value: (h, d) =>
                        h(
                            "p",
                            {
                                style: {
                                    "min-height": "80px",
                                },
                            },
                            d.pxxg || "tsqkjl"
                        ),
                },
                {
                    title: "巡访确认",
                    value: (h, d) => {
                        return h(
                            "div",
                            {
                                style: {
                                    display: "flex",
                                    "align-items": "center",
                                },
                            },
                            [
                                [`护士签名: ${d.fwry || ""}`, `日期: ${d.homeServiceTime || ""}`].map(v =>
                                    h(
                                        "p",
                                        {
                                            style: {
                                                width: "33.33%",
                                            },
                                        },
                                        v
                                    )
                                ),
                            ]
                        )
                    },
                },
            ],

            isExport: false,
        }
    },

    async created() {
        const appid = (this.appId = await this.getAppIdByTemplateId(103, this.orgCode))
        this.tableApi = `/gateway/api/fastDev/AppData/findPageData?appId=${appid}`
    },

    methods: {
        async getDetail(id) {
            // 置空
            this.appraise_value = null

            await Request.get("/gateway/api/fastDev/AppData/findDataById/" + id).then(async res => {
                const d = res.dataMap

                Object.keys(d).forEach(k => {
                    if (typeof d[k] === "string" && /^\[.*\]$/.test(d[k])) {
                        d[k] = JSON.parse(d[k])
                    }
                })

                d.photo = d.serviceImage

                this.detail = d

                if (!this.benefitAppId) {
                    this.benefitAppId = await this.getAppIdByTemplateId(107, this.orgCode)
                }

                const fi = await this.getFormdataId(id)

                if (fi) {
                    if (!this.appraise_forms) {
                        this.getForms(this.benefitAppId)
                    }
                    this.appraise_value = await this.getFormValue(fi)
                }
            })
        },

        getFormdataId(id) {
            return new Promise((resolve, reject) => {
                Request.get("/gateway/api/fastDev/AppData/searchAppDataByAppId", {
                    page: 1,
                    pageSize: 1,
                    orgCode: this.orgCode,
                    appId: this.benefitAppId,
                    searchValue: id,
                }).then(res => {
                    resolve(res.dataList?.[0]?.formDataId)
                })
            })
        },

        /**
         * 获取表单结构
         */
        async getForms(appId) {
            return Request.get("/gateway/api/fastDev/AppForm/findFormByAppId", {
                appId: appId,
            }).then(data => {
                const forms = []

                for (let i = 0, fps = data.formProperties; i < fps.length; i++) {
                    for (let fi = 0, fos = fps[i].formOperates; fi < fos.length; fi++) {
                        const v = fos[fi]

                        // 忽略详细地址组件，合并在 ElemAddr 组件
                        if (v.fieldType === "hidden") {
                            continue
                        }

                        if (v.options) {
                            v.options.forEach(o => {
                                o.name = o.label
                            })
                        }

                        v.max = v.limitMaxLen || (v.fieldType === "upload" ? (v.validate?.arrayType ? 9 : null) : null)

                        const type = this.getFormType(v)

                        if (type === "upload" && v.fieldType === "uploadFile") {
                            v.resource_type = "file"
                        }

                        forms.push({
                            ...v,
                            serial: v.sortIndex,
                            type: type,
                            name: v.fieldId,
                            title: v.fieldName,
                            verify: v.validate?.inputValueType,
                            multiple: v.validate?.arrayType,
                            unit: v.describe,
                        })
                    }
                }

                this.appraise_forms = forms
            })
        },

        /**
         * 获取表单项类型
         */
        getFormType(v) {
            if (v.validate?.inputValueType === "mobile") {
                return "mobile"
            }

            if (v.fieldType === "select" && v.childFormOperates?.length > 0) {
                return "radio"
            }

            if (v.fieldType === "uploadFile") {
                return "upload"
            }

            return { star: "rate" }[v.fieldType] || v.fieldType
        },

        getFormValue(id) {
            return new Promise(resolve => {
                Request.get(`/gateway/api/fastDev/AppData/findDataById/${id}`).then(res => {
                    resolve(res.dataMap)
                })
            })
        },

        getAppIdByTemplateId(id, orgCode) {
            return new Promise(async resolve => {
                Request.get("/gateway/api/fastDev/AppForm/findAppByTemplateIdAndOrgCode", {
                    templateId: id,
                    orgCode: orgCode,
                }).then(res => {
                    if (!res) return this.$Message.error((this.error = "当前机构尚未授权，请联系管理员！"))
                    resolve(res.appId)
                })
            })
        },

        onDataBefore(evt) {
            return evt.keyword
                ? [
                      {
                          queryFieldId: "userName",
                          queryFieldValue: evt.keyword,
                          searchMethod: "Contains",
                      },
                  ]
                : []
        },

        onProcessData(res) {
            const data = res.data
            const result = []

            data.dataList?.forEach((v, i) => {
                let item = {
                    id: v.formDataId,
                }

                v.columnDataList.forEach((d, i) => {
                    item[data.columnNameList[i]] = d
                })

                if (v.extendAttributeMap) {
                    Utils.eachObj(v.extendAttributeMap, (k, v) => {
                        item[k] = v
                    })
                }

                result.push(item)
            })

            return {
                ...data,
                dataList: result,
            }
        },

        onPredivImage() {},

        onLoadModal() {
            // 触发事件
            this.__onLoadModal?.()
            // 销毁事件
            this.__onLoadModal = null
        },
    },
}
</script>

<style lang="less">
.benefit-detail {
    .detail-base {
        display: flex;
        height: 100%;
    }

    .detail-box {
        width: 500px;
        height: 100%;
        overflow: auto;

        .info-box {
            width: 100%;
            height: 50px;
            display: flex;
            justify-content: space-around;
            align-items: center;
            border-bottom: 1px solid #e3e3e3;
        }

        .item-box {
            margin: 15px 20px;

            .title {
                font-size: 14px;
                color: #888;
                line-height: 25px;
                display: flex;
                align-items: center;
                flex-shrink: 0;

                .taro-checkbox_checked {
                    top: 0;
                    width: 16px;
                    height: 16px;
                    font-size: 16px;
                }

                &.bold {
                    font-weight: bold;
                    font-size: 16px;
                    color: #333;
                    margin-bottom: 10px;
                }
            }

            .content {
                color: #333;
                border-radius: 5px;
                font-size: 14px;

                .img {
                    margin: 0 10px 10px 0;
                    width: 125px;
                    height: 125px;
                    border-radius: 5px;
                    overflow: hidden;
                    float: left;
                }
            }

            &:not(.row) .content {
                margin-top: 5px;
                padding: 10px;
                background: #f5f5f5;
            }

            &.row {
                display: flex;
                align-items: flex-start;

                .title {
                    position: relative;
                    padding-right: 10px;

                    &::after {
                        content: ":";
                        position: absolute;
                        top: 0;
                        right: 2px;
                        bottom: 0;
                        display: flex;
                        align-items: center;
                    }
                }

                .content {
                    line-height: 20px;
                    margin: 2px 0 2px 10px;
                }
            }

            .value-item {
                margin-bottom: 12px;
                display: flex;

                .name {
                    color: #888;
                    line-height: 30px;
                    font-size: 14px;
                }

                .unit {
                    line-height: 30px;
                }

                .value {
                    padding: 0 30px;
                    margin: 0 10px;
                    background: #eeeeee;
                    border-radius: 5px;
                    height: 30px;
                    line-height: 30px;
                }

                .checkbox-box {
                    display: flex;
                    flex-direction: column;
                    margin: 6px 0 6px 15px;

                    .taro-checkbox_checked {
                        margin-right: 5px;
                    }
                }

                .radio-box {
                    margin: 6px 0 6px 15px;
                }

                > span {
                    margin-left: 10px;
                    line-height: 30px;
                }
            }
        }
    }

    .appraise-box {
        height: 100%;
        overflow: auto;
        border-left: 1px solid #e3e3e3;

        > .title {
            font-size: 18px;
            font-weight: bold;
            padding: 15px 10px 0 10px;
            text-align: center;
        }

        .comp-form-page {
            height: initial;
            padding: 0 20px;
        }
    }

    &.isexport {
        z-index: -1 !important;
        opacity: 0;

        .detail-base,
        .detail-box,
        .appraise-box {
            height: auto;
            min-height: 100%;
        }
    }
}
</style>
